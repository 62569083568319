<template>
  <AtroContent
    class="mx-auto w-full max-w-[var(--detail-width)] flex-1 space-y-8 px-2 py-5 sm:px-12 sm:py-6"
    direction="col"
    wrap="nowrap"
    justify="center"
  >
    <slot name="default" />
  </AtroContent>
</template>

<script setup lang="ts"></script>
